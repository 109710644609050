<template>
	<div @dblclick="dblClickHandler" class="modal-overlay active">
		<div @click.stop="" class="modal">
			<span @click="toggleModal" class="close" title="Fechar campo">
				<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" role="img" 
						xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" 
						class=""
				>
						<path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" class=""></path>
				</svg>
			</span>
						
			<div id="form">
				<h2 id="add_title">Editar usuário da Central StartLaw</h2>

				<form @submit.prevent="handleSubmit">
					<div id="master_input">
						<div>
							<div class="input-group">
								<label class="sr-only" for="nome">Nome</label>
								<input required autocomplete="off" 
									type="text" id="add_nome" name="nome" placeholder="Nome da pessoa"
									v-model="modalData.nome"
								>
							</div>

							<div class="input-group">
								<label class="sr-only" for="email">E-mail</label>
								<input required autocomplete="off" 
									type="email" id="add_email" name="email" placeholder="E-mail para contato e login"
									v-model="modalData.email"
								>
							</div>

							<div class="input-group">
								<label class="sr-only" for="senha">Senha</label>
								<input autocomplete="off" 
									type="password" id="add_senha" name="senha" placeholder="Senha de login"
									v-model="modalData.senha" @input="confirmSenhas"
								>
							</div>
							<small>Mantenha este campo vazio para não alterar a senha.</small>
							<div class="input-group" v-show="senhaPreenchida > 0">
								<label class="sr-only" for="senha_confirm">Confirme sua senha</label>
								<input autocomplete="off" 
									type="password" id="add_senha_confirm" name="senha_confirm" placeholder="Repita a senha"
									v-model="modalData.senha_confirm" @input="confirmSenhas"
								>
							</div>
							<small v-show="senhas_diferentes" id="aviso-senhas">A confirmação da senha está diferente!</small>
						</div>
						
						<div>
							<div class="input-group">
								<label class="sr-only" for="setor">Setor</label>
								<select name="setor" id="add_setor" required
									v-model="modalData.setor"
								>
									<option selected hidden value="">- Selecione um setor -</option>
									<option value="Comercial">Comercial</option>
									<option value="Comunicação">Comunicação</option>
									<option value="Customer Success">Customer Success</option>
									<option value="Financeiro">Financeiro</option>
									<option value="Administração">Administração</option>
									<option value="Gestão de Pessoas">Gestão de Pessoas</option>
									<option value="Jurídico">Jurídico</option>
									<option value="Tech">Tech</option>
								</select>
							</div>

							<div class="input-group">
								<label class="sr-only">Permissões</label>

								<div class="inner input-group checkbox">
									<input 
										type="checkbox" id="permissao_usuarios" name="permissao_usuarios"
										v-model="modalData.permissoes.usuarios"
									>
									<label class="sr-only" for="permissao_usuarios">Usuários</label>
								</div>

								<div class="inner input-group checkbox">
									<input 
										type="checkbox" id="permissao_avisos" name="permissao_avisos"
										v-model="modalData.permissoes.avisos"
									>
									<label class="sr-only" for="permissao_avisos">Avisos</label>
								</div>

								<div class="inner input-group checkbox">
									<input 
										type="checkbox" id="permissao_links" name="permissao_links"
										v-model="modalData.permissoes.links"
									>
									<label class="sr-only" for="permissao_links">Links</label>
								</div>

								<div class="inner input-group checkbox">
									<input 
										type="checkbox" id="permissao_demandas" name="permissao_demandas"
										v-model="modalData.permissoes.demandas"
									>
									<label class="sr-only" for="permissao_demandas">Demandas</label>
								</div>

								<div class="inner input-group checkbox">
									<input 
										type="checkbox" id="permissao_NPS" name="permissao_NPS"
										v-model="modalData.permissoes.NPS"
									>
									<label class="sr-only" for="permissao_NPS">NPS</label>
								</div>
							</div>
						</div>
					</div>

					<div class="input-group actions">
						<button type="button" @click="toggleModal" class="button cancel clear_button">Cancelar</button>
						<button class="button new">Editar</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import EventService from '@/services/EventService'
import handleError from '@/mixins/handleError'

import { UserEdit } from '@/utils/schemas'

export default {
	props: {
		usuario: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			modalData: {
				...UserEdit
			},
			senhas_diferentes: false,
		}
	},
	mixins: [handleError],
	mounted() {
		this.modalData = this.usuario
	},
	methods: {
		toggleModal() {
			this.$emit('toggleEditUsuario', { target: 'editUsuario' })
		},
		dblClickHandler(e) {
			if(e.target.className === 'modal-overlay active')
				this.toggleModal()
		},
		confirmSenhas() {
			const { senha, senha_confirm } = this.modalData
			if(!this.senhaPreenchida) {
				this.senhas_diferentes = false
				this.modalData.senha_confirm = ''
			}

			if(!senha_confirm || senha.length == 0 || senha_confirm.length == 0) return
			if(senha != senha_confirm) return this.senhas_diferentes = true
			return this.senhas_diferentes = false
		},
		handleSubmit() {
			if(this.senhas_diferentes) 
				return this.$Swal.fire('Tenha certeza de que sua senha e a confirmação delas são iguais!')

			let permissoes_concedidas = 0
			Object.keys(this.modalData.permissoes).forEach(permissao => permissoes_concedidas += this.modalData.permissoes[permissao])

			if(permissoes_concedidas == 0) 
				return this.$Swal.fire('Pelo menos uma permissão deve ser concedida.')

			const event = {
				...this.modalData,
			}
			delete event.senha_confirm

			this.$store.dispatch('loading')

			EventService.editUsuario(event)
			.then(({ alteracoes }) => {
				this.clearOnCreate()
				this.$emit('edittedUsuario', { alteracoes })
			})
			.catch(this.handleError)
		},
		clearOnCreate() {
			this.$store.dispatch('notLoading')

			this.modalData = UserEdit,

			this.toggleModal()
			
			this.$Swal.fire({
					title: "Usuário editado!",
					icon: "success"
			})
		},
	},
	computed: {
		senhaPreenchida() {
			return this.modalData.senha ? this.modalData.senha.length ? true : false : false
		}
	},
}
</script>

<style lang="scss" scoped>
@import '../Modals.scss';
@import "ModalUsuarios.scss";
</style>