<template>
  <div class="header_pagina">
    <h1>Usuários</h1>
		<my-button icone="add-user" texto="Adicionar usuário" @buttonClick="toggleModalLocal('addUsuario')" />
		<transition name="fade">
			<modal-add v-if="modalStates.addUsuario" @toggleAddUsuario="toggleModal" @createdUsuario="addUsuario"/>
		</transition>
	</div>

	<div id='section_filtro'>
		<div id="header_filtro">
				<h2 id='titulo_section_filtro'>Filtros de pesquisa</h2>
		</div>
		<div id="area_filtros">
			<div class="area_parametro_filtro">
				<label for="filtro_nome">Nome</label>
				<input class='filtro' name="filtro_nome" id="filtro_nome" placeholder="Nome"
			 		v-model="filter.nome"
				/>
			</div>

			<div class="area_parametro_filtro">
				<label for="filtro_email">E-mail</label>
				<input class='filtro' name="filtro_email" id="filtro_email" placeholder="E-mail"
					v-model="filter.email"
				/>
			</div>

			<div class="area_parametro_filtro">
				<label for="filtro_status">Setor</label>
				<select class='filtro' name="filtro_status" id="filtro_status"
			 		v-model="filter.setor"
				>
					<option value="" selected>- Sem restrição -</option>
					<option value="Administração">Administração</option>
					<option value="Comercial">Comercial</option>
					<option value="Comunicação">Comunicação</option>
					<option value="Customer Success">Customer Success</option>
					<option value="Financeiro">Financeiro</option>
					<option value="Gestão de Pessoas">Gestão de Pessoas</option>
					<option value="Jurídico">Jurídico</option>
					<option value="Tech">Tech</option>
				</select>
			</div>
		</div>
	</div>
	
	<section id="acoes">
		<div id="regiao_acoes">
			<my-button icone="delete" texto="Deletar" 
				style="margin-right: 1rem;"
				v-if="usuariosSelecionados.length > 0"
				@click="verifyDelete()"
			/>

			<my-button icone="edit" texto="Editar" 
				v-if="usuariosSelecionados.length == 1"
				@buttonClick="toggleModalLocal('editUsuario')"
			/>
			<transition name="fade">
				<modal-edit
					v-if="modalStates.editUsuario" 
					:usuario="usuarioEdit"
					@toggleEditUsuario="toggleModal" 
					@edittedUsuario="updateUsuario"
				/>
			</transition>
		</div>
	</section>

  <section>
    <div style="max-width: calc(100% - 2rem); margin: auto;">
			<p v-if="!loaded">Carregando...</p>
			<div v-else>
				<tabela-users 
					:usuarios="usuarios"
					:filter="filter"
					@checkmarkChange="selectUsers"
				></tabela-users>
			</div>
		</div>
  </section>
</template>

<script>
import EventService from "@/services/EventService";

import handleError from '@/mixins/handleError'
import toggleModals from '@/mixins/toggleModals'

import ModalAdd from '@/components/Modals/Usuarios/Add.vue'
import ModalEdit from '@/components/Modals/Usuarios/Edit'

import TabelaUsers from '@/components/Tables/Users/Users.vue'

import Button from '@/components/Buttons/Button.vue'

export default {
	components: {
		'modal-add': ModalAdd,
		'modal-edit': ModalEdit,
		'my-button': Button,
		'tabela-users': TabelaUsers,
	},
  data() {
    return {
			loaded: false,
      usuarios: [],
			usuarioEdit: {},
			modalStates: {
				addUsuario: false,
				editUsuario: false,
			},

      filter: {
        nome: '',
        email: '',
        setor: ''
      },

			usuariosSelecionados: []
    };
  },
  async mounted() {
    await this.loadUsuarios()
		this.loaded = true
  },
  mixins: [handleError, toggleModals],
	methods: {
		async loadUsuarios() {
			const response = await EventService.fetchUsuarios()
			this.usuarios = response.usuarios;
		},
    addUsuario(data) {
      let flag_inserted = false
      const novo_nome = data.usuario_criado.nome.toUpperCase()

      for(let index = 0; index < this.usuarios.length; index++){
        const nome_index = this.usuarios[index].nome.toUpperCase()
        
        if(nome_index > novo_nome){
          this.usuarios.splice(index, 0, data.usuario_criado)
          flag_inserted = true
          break
        }
      }

      if(!flag_inserted) this.usuarios.push(data.usuario_criado)
    },

		toggleModalEdit(data) {
			this.usuarioEdit = data.usuario
			this.modalStates.editUsuario = true
		},
    updateUsuario({ alteracoes }) {
			const chaves_alteracoes = Object.keys(alteracoes)
			const flag_nome = chaves_alteracoes.includes('nome')
			const flag_email = chaves_alteracoes.includes('email')
			const flag_setor = chaves_alteracoes.includes('setor')

			if(flag_nome || flag_email || flag_setor) {
				for(let index=0; index < this.usuarios.length; index++){
					if(this.usuarios[index]._id == alteracoes.id){
						if(flag_nome) this.usuarios[index].nome = alteracoes.nome
						if(flag_email) this.usuarios[index].email = alteracoes.email
						if(flag_setor) this.usuarios[index].setor = alteracoes.setor
						break
					}
				}	
			}
		},

		selectUsers({ usuariosSelecionados }) {
			if(usuariosSelecionados.length == 1) {
				const id_edit = usuariosSelecionados[0]
				const user_edit = this.usuarios.filter(user => user._id == id_edit)[0]
				this.usuarioEdit = user_edit
			}
			else this.usuarioEdit = {}

			this.usuariosSelecionados = usuariosSelecionados
		},

		verifyDelete(){
			const seu_id = this.$store.state.user.id

			if(this.usuariosSelecionados.includes(seu_id)){
				return this.$Swal.fire('Você não pode se deletar!')
			}

			this.toggleDeleteUsuarios()
		},
    toggleDeleteUsuarios() {

			let title = `Deletar usuário`
			let text = `Tem certeza que quer deletar o usuário ${this.usuariosSelecionados[0]}?`

			if(this.usuariosSelecionados.length > 1) {
				title += `s`
				text = `Tem certeza que quer deletar ${this.usuariosSelecionados.length} usuários?`
			}

      this.$Swal.fire({
        title,
        text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (!result.isConfirmed) return
        try {
					const ids = [...this.usuariosSelecionados]
					EventService.deleteUsuarios({ ids })
					.then(({ usuario_criado }) => {
						this.clearSelected()
						this.$store.dispatch('notLoading')
						this.$Swal.fire({
								title: "Operação de deleção concluída!",
								icon: "success"
						})
					})
					.catch(this.handleError)
        } catch(e) {
          this.handleError(e)
        }
      })
    },
		clearSelected() {
			const usuarios_atuais = this.usuarios.filter(usuario => {
				return !this.usuariosSelecionados.includes(usuario._id)
			})
			this.usuarios = usuarios_atuais
			this.usuariosSelecionados = []
			this.usuarioEdit = {}
		},
	}
}
</script>

<style lang="scss" scoped>
@import "/Usuarios.scss";
</style>