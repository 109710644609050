<template>
  <div id="area_tabela">
    <table>
      <thead>
        <tr>
          <th></th>
          <th>Nome</th>
          <th>E-mail</th>
          <th>Setor</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(usuario) in usuarios" :key="usuario._id"
          v-show="filtro(usuario)"
        >
          <td class="acao" style="padding: 1rem;">
            <label class="container_check">
              <input class="soli_selecionada" :value="usuario._id" type="checkbox"
                v-model="selecionados" @change="emmitChange"
              >
              <span class="checkmark"></span>
            </label>
          </td>
          <td>{{usuario.nome}}</td>
          <td>{{usuario.email}}</td>
          <td>{{usuario.setor}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Checkmark from '@/components/Checkmark/Checkmark.vue'

export default {
  components: {
    'checkmark': Checkmark,
  },
  data() {
    return {
      selecionados: []
    }
  },
  props: {
    usuarios: {
      type: Array,
      required: true
    },
    filter: {
      type: Object,
      required: false
    },
  },
  methods: {
    emmitChange() {
      this.$emit('checkmarkChange', { usuariosSelecionados: this.selecionados })
    },
    filtro(user) {
      if(this.remove_filter || !this.filter) return true

      const nome = user.nome.toUpperCase()
      const filtro_nome = this.filter.nome.toUpperCase()
      const flag_nome = nome.includes(filtro_nome)

      const setor = user.setor.toUpperCase()
      const filtro_setor = this.filter.setor.toUpperCase()
      const flag_setor = setor.includes(filtro_setor)

      const email = user.email.toUpperCase()
      const filtro_email = this.filter.email.toUpperCase()
      const flag_email = email.includes(filtro_email)

      return flag_nome && flag_setor && flag_email
    },
  }
}
</script>

<style lang="scss" scoped>
@import 'Users.scss';
@import '../Tables.scss';
</style>